import {ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges} from "@angular/core";
import {CheckboxControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ErrorStateMatcher} from "@angular/material/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {UntilDestroy} from "@ngneat/until-destroy";
import {BbFormErrorStateMatcher} from "../bb-form-error-state-matcher";
import {BbFormModule} from "../bb-form.module";

@UntilDestroy()
@Component({
    selector: "bb-checkbox",
    templateUrl: "./bb-checkbox.component.html",
    styleUrls: ["../bb-form.styles.scss"],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        CheckboxControlValueAccessor,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxControlValueAccessor),
            multi: true,
        },
        {
            provide: ErrorStateMatcher,
            useClass: BbFormErrorStateMatcher,
        },
    ],
    standalone: true,
    imports: [BbFormModule, MatCheckboxModule, MatTooltipModule],
})
export class BbCheckboxComponent implements OnChanges {
    @Input() label: string = "";
    @Input() required: boolean = false;
	@Input() tooltip: Nullable<string> = null;
	@Input() indeterminate = false;

    public get control(): FormControl {
        if (this.formControl?.control == null) {
            throw new Error("Control not found");
        }
        return this.formControl.control as FormControl;
    }

    constructor(private readonly formControl: NgControl,
    ) {
	}
	
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.indeterminate.currentValue === true) {
			this.control.setValue(false);
		}
	}
}
