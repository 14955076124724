import {Inject, Injectable} from "@angular/core";
import {
	CloudStorage,
	OrderToShip,
	PackageType, RecipientDetails, RecipientOptions,
	SearchOrderResult, ShipmentPdfLocation, ShippingOptions,
	ShippingProvider,
} from "@app/app/shipping/core/entity";
import {ShippingStationOptions} from "@app/app/shipping/core/entity/shipping-station-options";
import {FoundOrder} from "@app/app/shipping/data/model/found-order";
import {RestService} from "@bb-data/service/rest.service";
import * as ngx from "@ngx-resource/core";
import * as core from "../../core/repository";
import * as model from "../model";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/shipping",
})
export class ShippingRepository extends RestService implements core.ShipmentRepository {

    @ngx.ResourceAction({
        path: "/searchorder",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doSearchOrders: ngx.IResourceMethod<model.SearchOrdersRequest, FoundOrder[]>;

    @ngx.ResourceAction({
        path: "/shippingorder/{orderId}",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetOrderForSingleShip: ngx.IResourceMethod<{ orderId: number }, OrderToShip>;

    @ngx.ResourceAction({
        pathPrefix: "/api/angular/settings",
        path: "/shippingprovider",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetShippingProviders: ngx.IResourceMethod<void, ShippingProvider[]>;

    @ngx.ResourceAction({
        pathPrefix: "/api/angular/settings",
        path: "/shippingprovider/shippingStation",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetShippingStationOptions: ngx.IResourceMethod<void, ShippingStationOptions>;

    @ngx.ResourceAction({
        pathPrefix: "/api/angular/settings",
        path: "/shippingprovider/shippingpackages",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetPackageTypes: ngx.IResourceMethod<void, PackageType[]>;

    @ngx.ResourceAction({
        pathPrefix: "/api/angular",
        path: "/cloud",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetCloudStorages: ngx.IResourceMethod<void, CloudStorage[]>;

    @ngx.ResourceAction({
        path: "/shipsingle",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doShipSingleOrder: ngx.IResourceMethod<OrderToShip, void>;

    @ngx.ResourceAction({
        path: "/{type}?batchid={batchId}&shippingproviderid={providerId}",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetShipmentPdfLocation: ngx.IResourceMethod<{ type: string, batchId: string, providerId: number }, ShipmentPdfLocation>;

    @ngx.ResourceAction({
        path: "/getmultiple",
        method: ngx.ResourceRequestMethod.Post,
        keepEmptyBody: true,
    })
    private doGetOrdersForBulkShip: ngx.IResourceMethod<number[], OrderToShip[]>;

    @ngx.ResourceAction({
        pathPrefix: "/api/angular/settings",
        path: "/shippingprovider/options",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetShippingOptions: ngx.IResourceMethod<void, ShippingOptions>;

    @ngx.ResourceAction({
        path: "/recipient-options/{orderId}",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetRecipientOptions: ngx.IResourceMethod<void, RecipientOptions>;

    @ngx.ResourceAction({
        path: "/recipient-details/{orderId}",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doUpdateRecipientDetails: ngx.IResourceMethod<RecipientDetails, RecipientDetails>;

    public async searchOrders(term: string): Promise<SearchOrderResult[]> {
        const result = await this.doSearchOrders(new model.SearchOrdersRequest({QueryString: term}));
        return result.map(e => new SearchOrderResult({
            ExternalReference: e.ExternalReference,
            CustomerName: e.Customer,
            ShipDate: e.ShippingDate != null ? new Date(e.ShippingDate) : null,
            OrderDate: new Date(e.OrderDate),
            CustomerNumber: Number.parseInt(e.CustomerId, 10),
            OrderId: e.Id,
            SalesChannel: e.Platform,
            ShippingAddress: e.ShippingAddress,
            ArchiveState: e.ArchiveState,
        }));
    }

    public getOrderForSingleShip(orderId: number): Promise<OrderToShip> {
        return this.doGetOrderForSingleShip({orderId}).then(o => new OrderToShip(o));
    }

    public async getShippingProviders(): Promise<ShippingProvider[]> {
        const result = await this.doGetShippingProviders();
        return result.map(e => new ShippingProvider(e));
    }

    public async getShippingStationOptions(): Promise<ShippingStationOptions> {
        return new ShippingStationOptions(await this.doGetShippingStationOptions());
    }

    public async getPackageTypes(): Promise<PackageType[]> {
        const result = await this.doGetPackageTypes();
        return result.map(e => new PackageType(e));
    }

    public async getCloudStorages(): Promise<CloudStorage[]> {
        const result = await this.doGetCloudStorages();
        return result.map(e => new CloudStorage(e));
    }

    public async shipSingleOrder(orderToShip: OrderToShip): Promise<void> {
        orderToShip.SignalRConnectionId = this.sigR.connectionId;
        await this.doShipSingleOrder(orderToShip);
    }

    public async getLabelsForBatchAndProvider(batchId: string,
                                              providerId: number,
    ): Promise<ShipmentPdfLocation> {
        return new ShipmentPdfLocation(await this.doGetShipmentPdfLocation({
            type: "getlabelsbybatchid",
            batchId,
            providerId,
        }));
    }

    public async getExportDocumentsForBatchAndProvider(batchId: string,
                                                       providerId: number,
    ): Promise<ShipmentPdfLocation> {
        return new ShipmentPdfLocation(await this.doGetShipmentPdfLocation({
            type: "getexportdocsbybatchid",
            batchId,
            providerId,
        }));
    }

    public async getReturnLabelsForBatchAndProvider(batchId: string,
                                                       providerId: number,
    ): Promise<ShipmentPdfLocation> {
        return new ShipmentPdfLocation(await this.doGetShipmentPdfLocation({
            type: "getretourelabelsbybatchid",
            batchId,
            providerId,
        }));
    }

    public async getOrdersForBulkShip(orderIds: number[]): Promise<OrderToShip[]> {
        const result = await this.doGetOrdersForBulkShip(orderIds);
        return result.map((m) => new OrderToShip(m));
    }

    public getReadyForShip(): Promise<OrderToShip[]> {
        // todo
        throw new Error("Method not implemented.");
    }

    public async getShippingOptions(): Promise<ShippingOptions> {
        const result = await this.doGetShippingOptions();
        return new ShippingOptions(result);
    }

    public async getRecipientOptions(orderId: number,
    ): Promise<RecipientOptions> {
        return new RecipientOptions(await this.doGetRecipientOptions(null, null, {
            orderId,
        }));
    }

    public async updateRecipientDetails(orderId: number,
                                        recipientDetails: RecipientDetails,
    ): Promise<RecipientDetails> {
        return new RecipientDetails(await this.doUpdateRecipientDetails(recipientDetails, null, {
            orderId,
        }));
    }

    constructor(restHandler: ngx.ResourceHandler, @Inject("SignalRService") private readonly sigR: SignalRService) {
        super(restHandler);
    }

}
