import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {ShopKey} from "../../model/shop-key";
import {ShopsService} from "../shops.service";

@Injectable({
	providedIn: "root",
})
export class GetOAuthViewModelCommand {
    constructor(private readonly shopsService: ShopsService) {}

    execute(key: ShopKey, logo: string, name: string, url: string, earliestStartDate?: Date): Observable<ShopViewModelDto> {
        return this.shopsService
            .getOauthDetails(logo, url)
            .pipe(
                switchMap((oauth) =>
                    (earliestStartDate
                        ? this.shopsService.getBaseDetails(key, name, earliestStartDate)
                        : this.shopsService.getBaseDetails(key, name)
                    ).pipe(map((viewModel) => ({...viewModel, OAuthAccessToken: oauth}))),
                ),
            );
    }
}
