import {Pipe, PipeTransform} from "@angular/core";
import {ShopViewModelDto} from "../../../../partners/shops/data/shop-view-model.dto";
import {ShopSyncState} from "../../model/shop-sync-state.enum";

@Pipe({
    name: "bbStatusColorFromShop",
    standalone: true,
})
export class StatusColorFromShopPipe implements PipeTransform {
	transform(shop: ShopViewModelDto): "success" | "error" | "default" {
		if (shop.Partner === "manual") {
			return "default";
		}

		if (
            shop.LastSyncState === ShopSyncState.ReauthRequired ||
            shop.LastSyncState === ShopSyncState.SyncFailedReconnectRequired
		) {
			return "error";
		}
		
		return "success";
	}
}