import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {CustomerListViewModel} from "./customer-list.view-model";
import {
    CustomerRepository,
    DeleteCustomerRequest,
    Customer,
    ImportCustomersUseCase,
    ExportCustomersPresenter,
    ImportCustomersPresenter,
    MergeCustomersUseCase,
    MergeCustomersRequest,
    MergeCustomersPresenter,
    ShowCustomerListUseCase,
    ShowCustomerListPresenter,
    CustomerListPermissions,
} from "@app/app/customer/core";
import {NavigationService, TranslationService} from "@bb-core/service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DeleteCustomerUseCase} from "@app/app/customer/core/use-case/edit-customer/delete-customer.use-case";
import {BbGridComponent} from "@app/app/grid/presentation/bb-grid.component";
import {
    ExportCustomersRequest,
    ExportCustomersUseCase,
} from "@app/app/customer/core/use-case/export-customers.use-case";
import {Column, Preset} from "@app/app/grid/core";
import {LanguageService} from "@app/app/common/service/da/language.service";
import {ApiPriceGroupRepository} from "@app/app/customer/data";

@Component({
    selector: "bb-customer-list",
    templateUrl: "./customer-list.component.html",
})
export class CustomerListComponent
    implements OnInit,
        OnDestroy,
        ShowCustomerListPresenter,
        ExportCustomersPresenter,
        ImportCustomersPresenter,
        MergeCustomersPresenter {
    public readonly viewModel: CustomerListViewModel;

    private readonly subscriptions: Subscription[] = [];

    @ViewChild("grid")
    public grid: BbGridComponent;

    constructor(public readonly customerRepo: CustomerRepository,
                private readonly translator: TranslationService,
                private readonly navigationService: NavigationService,
                private readonly route: ActivatedRoute,
                private readonly deleteCustomerUseCase: DeleteCustomerUseCase,
                private readonly exportCustomersUseCase: ExportCustomersUseCase,
                private readonly languageService: LanguageService,
                private readonly importCustomersUseCase: ImportCustomersUseCase,
                private readonly mergeCustomersUseCase: MergeCustomersUseCase,
                private readonly priceGroupRepo: ApiPriceGroupRepository,
                private readonly showCustomerListUseCase: ShowCustomerListUseCase,
    ) {
        this.viewModel = new CustomerListViewModel(translator);
        new Promise<void>(async (resolve) => {
            await Promise.all([
                languageService
                    .queryAll()
                    .then(r => this.viewModel.languageColumn.LookupValues = r.map(l => ({text: l.Name, value: l.Id}))),

                priceGroupRepo
                    .fetchSelectOptions()
                    .then((r) => this.viewModel.priceGroupColumn.LookupValues = r),
            ]);
            this.viewModel.dependenciesLoaded = true;
            await showCustomerListUseCase.execute(null, this);
            resolve();
        }).then();

    }

    // region Life cycle hooks

    public ngOnInit(): void {
        this.subscriptions.push(this.route.queryParams.subscribe(params => {
            if ("OpenCustomer" in params) {
                window.setTimeout(() => this.openCustomer(Number(params.OpenCustomer)), 500);
            }
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    // endregion

    public removeDeletedCustomers(): void {
        this.grid.grid.instance.refresh();
    }

    public openCustomer(customerId: number): void {
        this.navigationService.navigateToEditCustomer(customerId);
    }

    public async deleteCustomers(selectedIds: number[]): Promise<void> {
        await this.deleteCustomerUseCase.execute(new DeleteCustomerRequest({SelectedIds: selectedIds}), this);
    }

    public async onExportClicked(selectedIds: number[], preset: Preset<Customer>): Promise<void> {
        await this.exportCustomersUseCase.execute(new ExportCustomersRequest({
            SelectedIds: selectedIds,
            Columns: preset.Columns.map(x => new Column<Customer>(x)),
        }));
    }

    public onCreateOrderClicked(customerId: number): void {
        window.open(`/app_v2/order?openOrder=-1&CustomerId=${customerId}`);
    }

    public async onImportClicked(): Promise<void> {
        await this.importCustomersUseCase.execute(null, this);
    }

    public async mergeCustomers(customerIds: number[]): Promise<void> {
        await this.mergeCustomersUseCase.execute(new MergeCustomersRequest({
            CustomerIds: customerIds,
        }), this);
    }

    public removeMergedCustomers(customers: Customer[]): void {
        this.grid.refresh().then();
    }

    public applyPermissions(permissions: CustomerListPermissions): void {
        this.viewModel.canEdit = permissions.canEdit;
        this.viewModel.canCreate = permissions.canCreate;
        this.viewModel.canDelete = permissions.canDelete;
        this.viewModel.canImport = permissions.canImport;
        this.viewModel.canExport = permissions.canExport;
        this.viewModel.canMerge = permissions.canMerge;
        this.viewModel.canList = permissions.canList;
        this.viewModel.canCreateOrder = permissions.canCreateOrder;
        this.viewModel.gridOptions.ShowEditButton = permissions.canEdit;
    }
}
