import {NgModule} from "@angular/core";
import * as useCase from "./core/use-case";
import * as coreRepo from "./core/repository";
import * as coreSvc from "./core/service";
import {ShippingDependenciesService} from "./core/service";
import * as dataRepo from "./data/repository";
import * as infraSvc from "./infrastructure/service";
import {ShippingHelperService} from "./infrastructure/service";

@NgModule({
    providers: [
        useCase.SearchOrdersUseCase,
        useCase.LoadOrderToShipUseCase,
        useCase.SelectShippingProviderUseCase,
        useCase.ShipSingleOrderUseCase,
        useCase.EditAddressUseCase,
        useCase.PickAddressUseCase,
        useCase.ApplyWeightUseCase,
        useCase.SelectShippingProductUseCase,
        useCase.DownloadShipmentPdfUseCase,
        useCase.LoadOrdersUseCase,
        useCase.SortOrdersUseCase,
        ShippingDependenciesService,
        ShippingHelperService,
        {provide: coreRepo.AddressRepository, useClass: dataRepo.AddressRepository},
        {provide: coreRepo.ShipmentRepository, useClass: dataRepo.ShippingRepository},
        {provide: coreSvc.ShippingInteractionService, useClass: infraSvc.ShippingInteractionService},
    ],
})
export class ShippingPublicApiModule {
}
