<mat-card fxFlex="33" [fxFlex.lt-md]="50" [fxFlex.lt-sm]="100">
    <h3 mat-dialog-title>{{ 'title.select_shopify_connection_type' | translate }}</h3>
    <mat-card-content>
        <div fxLayout="column" fxLayoutGap="16px">
            <button type="button" mat-flat-button color="primary" (click)="openLink('channel')">
                {{ 'label.sales_channel' | translate }}
            </button>
            <button type="button" mat-flat-button color="primary" (click)="openLink('payment')">
                {{ 'button.payment_import' | translate }}
            </button>
        </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end">
        <button type="button" mat-flat-button routerLink="/">
            {{ 'button.cancel' | translate }}
        </button>
    </mat-card-actions>
</mat-card>