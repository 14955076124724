import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {ShopKey} from "../model/shop-key";
import {ShopViewModelDto} from "./shop-view-model.dto";

@Injectable({
    providedIn: "root",
})
export class ShopsRepository {
    constructor(private readonly httpClient: HttpClient) {}

    public getAuthorizeUrl(shop: ShopKey, queryParams?: any): Observable<string> {
        const params = new HttpParams(queryParams ? {fromObject: queryParams} : {});

        return this.httpClient
            .get<{redirecturl: string}>(`${environment.baseUrl}/settings/shop/${shop}auth`, {params})
            .pipe(map(({redirecturl}) => redirecturl));
    }

    public getAuth(shop: ShopKey, queryParams?: any): Observable<{AccessToken: string}> {
        const params = new HttpParams(queryParams ? {fromObject: queryParams} : {});

        return this.httpClient.get<{AccessToken: string}>(`${environment.baseUrl}/settings/shop/${shop}auth`, {params});
    }

    public getViewmodel(shopKey: ShopKey): Observable<ShopViewModelDto> {
        const params = new HttpParams().set("typeName", shopKey);

        return this.httpClient.get<ShopViewModelDto>(`${environment.baseUrl}/settings/shop/viewmodel`, {params}).pipe(
            map(({Id, ...viewModel}) => ({
                ...viewModel,
                PartnerDisplayText: shopKey,
                partnerAsString: shopKey,
                AutoSyncOnLogin: true,
                PIIArchiveActive: viewModel.PIIArchivePeriod > 0,
                ApiVersion: 2,
                StateSyncMode: 3,
                DownloadOrders: true,
                ResyncOrderItems: false,
                ForbidOrderItemEdit: false,
                DefaultVatMode: 0,
                DefaultVatIndex: 1,
                ShippingProfileMappings: [],
                CouponVatIndex: 0,
            })),
        );
    }

    public saveShop(viewModel: ShopViewModelDto): Observable<ShopViewModelDto> {
        return this.httpClient.post<ShopViewModelDto>(`${environment.baseUrl}/settings/shop`, viewModel);
    }

    public updateShop(viewModel: ShopViewModelDto): Observable<ShopViewModelDto> {
        return this.httpClient.put<ShopViewModelDto>(`${environment.baseUrl}/settings/shop/${viewModel.Id}`, viewModel);
    }

    public testConnection(viewModel: ShopViewModelDto): Observable<ShopViewModelDto> {
        return this.httpClient.post<ShopViewModelDto>(
            `${environment.baseUrl}/settings/shop/checkConnection`,
            viewModel,
        );
    }

    public getAmazonAvailableMarketPlaceIds(accessToken: string, region: string): Observable<string[]> {
        const params = new HttpParams().set("accessToken", accessToken).set("region", region);
        return this.httpClient
            .get<{marketplaceIds: string[]}>(`${environment.baseUrl}/settings/shop/amazonGetAvailableMarketplaceIds`, {
                params,
            })
            .pipe(map(({marketplaceIds}) => marketplaceIds));
    }

    public shopifyShopCheck(shopName: string): Observable<{ShopExists: boolean; ShopId: number}> {
        const params = new HttpParams().set("shopName", shopName);
        return this.httpClient.get<{ShopExists: boolean; ShopId: number}>(
            `${environment.baseUrl}/settings/shop/shopifyshopcheck`,
            {params},
        );
    }
}
