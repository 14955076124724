import {Injectable} from "@angular/core";

import {
    CustomerImportSettings,
    CustomerInteractionService,
    CustomerRepository,
    DirectionEnum,
    FileFormat,
} from "@app/app/customer/core";
import {
    ClientStorageService,
    ErrorHandlerService,
    Message,
    MessagingService,
    TranslationService,
} from "@bb-core/service";

export abstract class ImportCustomersPresenter {
}

@Injectable({providedIn: "root"})
export class ImportCustomersUseCase implements IUseCase<void, ImportCustomersPresenter> {

    constructor(private readonly customerInteractionService: CustomerInteractionService,
                private readonly customerRepository: CustomerRepository,
                private readonly clientStorage: ClientStorageService,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(request: void,
                         presenter?: ImportCustomersPresenter,
    ): Promise<void> {
        const importFormats = await this.getImportFormats();
        let importSettings = this.getInitialImportSettings(importFormats);

        importSettings = await this.customerInteractionService.enterImportSettings(
            importSettings,
            importFormats,
        );

        if (importSettings == null) {
            return;
        }

        await this.startImport(importSettings);
    }

    private async startImport(exportSettings: CustomerImportSettings): Promise<void> {
        try {
            const message = this.translator.translate("flash.customer_import_started");
            await this.customerRepository.startImport(exportSettings);
            await this.messaging.showMessage(Message.transient({message}));
        } catch (e) {
            await this.errorHandler.handleException(e, null, true);
        }
    }

    private async getImportFormats(): Promise<FileFormat[]> {
        const fileFormats = await this.customerRepository.getFileFormats();
        return fileFormats.filter((f) => f.FileFormatDirection === DirectionEnum.Import);
    }

    private getInitialImportSettings(importFormats: FileFormat[],
    ): CustomerImportSettings {
        let importFormat = this.clientStorage.get<string>("customer.import_format");
        if (!importFormats.find(f => f.Value === importFormat)) {
            importFormat = importFormats.find((_) => true)?.Value;
        }

        return new CustomerImportSettings({
            FileFormat: importFormat,
        });
    }
}
