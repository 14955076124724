import {ChangeDetectionStrategy, Component, forwardRef, Injector, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR, ValidationErrors} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {TranslateModule} from "@ngx-translate/core";
import {BbFormErrorStateMatcher} from "../bb-form-error-state-matcher";
import {BbFormModule} from "../bb-form.module";

@UntilDestroy()
@Component({
    selector: "bb-datepicker",
    templateUrl: "./bb-datepicker.component.html",
    styleUrls: ["../bb-form.styles.scss"],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BbDatePickerComponent),
            multi: true,
        },
        {
            provide: ErrorStateMatcher,
            useClass: BbFormErrorStateMatcher,
        },
    ],
    standalone: true,
    imports: [BbFormModule, MatInputModule, MatDatepickerModule, TranslateModule, MatTooltipModule],
})
export class BbDatePickerComponent implements ControlValueAccessor, OnInit {
    @Input() label: string = "";
    @Input() tooltip: string = "";
    @Input() required: boolean = false;
    @Input() min: Date | null = null;
    @Input() customErrors: ValidationErrors[] = [];

    public readonly control = new FormControl("");

    private onChange = (v: any) => {};
    private onTouched = () => {};

    constructor(private readonly injector: Injector) {}

    public ngOnInit(): void {
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.onTouched();
            this.onChange(value);
        });

        this.control.setValidators(this.injector.get(NgControl).control?.validator || null);
    }

    public writeValue(obj: string): void {
        this.control.setValue(obj, {emitEvent: false});
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }
}
