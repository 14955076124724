import {
    Customer,
    CustomerAddress,
    CustomerExportSettings,
    CustomerImportSettings,
    CustomerMetaInformation,
    CustomerRepository,
    FileFormat, ShowCustomerImportResultRequest, ShowCustomerImportResultUseCase,
} from "@app/app/customer/core";
import {Inject, Injectable} from "@angular/core";
import {Column, PageResult, Preset} from "@app/app/grid/core";
import * as ngx from "@ngx-resource/core";
import {ResourceRequestBodyType} from "@ngx-resource/core";
import {TranslationService} from "@bb-core/service";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/customer",
})
export class ApiCustomerRepository extends ngx.Resource implements CustomerRepository {
    public readonly uniqueIdentifier = "customer";

    @ngx.ResourceAction({
        path: "/list",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doQueryPage: ngx.IResourceMethod<any, { Data: Customer[], TotalCount: number }>;

    @ngx.ResourceAction({
        path: "/{id}",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetCustomerById: ngx.IResourceMethod<{ id: number }, Customer>;

    @ngx.ResourceAction({
        path: "/{customerId}/addresses",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetCustomerAddresses: ngx.IResourceMethod<{ customerId: number }, CustomerAddress[]>;

    @ngx.ResourceAction({
        path: "/{Id}/update-property",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doUpdateProperty: ngx.IResourceMethod<{ Id: number, Changes: Partial<Customer> }, any>;

    @ngx.ResourceAction({
        path: "/save",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doSave: ngx.IResourceMethod<Customer, Customer>;

    @ngx.ResourceAction({
        path: "/delete-multiple",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doDelete: ngx.IResourceMethod<number[], void>;

    @ngx.ResourceAction({
        path: "/filter",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetPresets: ngx.IResourceMethod<void, { filter: string }>;

    @ngx.ResourceAction({
        path: "/filter",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doSavePresets: ngx.IResourceMethod<{ FilterData: string }, void>;

    @ngx.ResourceAction({
        path: "/file-formats",
        method: ngx.ResourceRequestMethod.Get,
    })
    private doGetFileFormats: ngx.IResourceMethod<void, FileFormat[]>;

    @ngx.ResourceAction({
        path: "/export",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doStartExport: ngx.IResourceMethod<CustomerExportSettings, void>;

    @ngx.ResourceAction({
        path: "/import",
        method: ngx.ResourceRequestMethod.Post,
        requestBodyType: ResourceRequestBodyType.FORM_DATA,
    })
    private doStartImport: ngx.IResourceMethod<FormData, void>;

    @ngx.ResourceAction({
        path: "/get-multiple",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doGetMultipleCustomers: ngx.IResourceMethod<number[], Customer[]>;

    @ngx.ResourceAction({
        path: "/merge-multiple",
        method: ngx.ResourceRequestMethod.Post,
    })
    private doMergeCustomers: ngx.IResourceMethod<{ CustomerIdsToMerge: number[], Customer: Customer }, Customer>;

    @ngx.ResourceAction({
        path: "/meta-data",
        method: ngx.ResourceRequestMethod.Patch,
    })
    private doUpdateMetaData: ngx.IResourceMethod<CustomerMetaInformation, CustomerMetaInformation>;

    private importSignalRSubscribed: boolean = false;

    public async queryPage(page: number,
                           pageSize: number,
                           visibleColumns: Column<Customer>[],
                           searchTerm: string,
                           additionalArguments: any = null,
    ): Promise<PageResult<Customer>> {
        const data = await this.doQueryPage({
            Page: page,
            PageSize: Math.min(100, pageSize),
            VisibleColumns: visibleColumns,
            SearchTerm: searchTerm,
        }, null, additionalArguments);

        return new PageResult<Customer>({
            Data: data.Data.slice(0, pageSize),
            Page: page,
            PageSize: pageSize,
            TotalCount: data.TotalCount,
        });
    }

    public async getCustomerById(id: number): Promise<Customer> {
        return new Customer(await this.doGetCustomerById({id}));
    }

    public async getAddresses(customerId: number): Promise<CustomerAddress[]> {
        return (await this.doGetCustomerAddresses({customerId})).map((a) => new CustomerAddress(a));
    }

    public async updateProperty(id: number, changes: Partial<Customer>): Promise<Customer> {
        return new Customer(await this.doUpdateProperty({Id: id, Changes: changes}));
    }

    public async save(customer: Customer): Promise<Customer> {
        return new Customer(await this.doSave(customer));
    }

    public deleteCustomers(customerIds: number[]): Promise<void> {
        return this.doDelete(customerIds);
    }

    public async getPresets(): Promise<Preset<Customer>[]> {
        const result = await this.doGetPresets();
        const parsed = JSON.parse(result.filter);
        if (!Array.isArray(parsed)) {
            return [];
        }

        parsed.unshift(new Preset<Customer>({
            Name: this.translator.translate("text.preset_all_customers"),
            IsSystemPreset: true,
        }));

        return parsed.map((e) => {
            const preset = new Preset<Customer>(e);
            if (preset.Id == null) {
                preset.Id = (Math.random() + 1).toString(36).substring(7);
            }

            return preset;
        });
    }

    public async savePresets(filters: Preset<Customer>[]): Promise<Preset<Customer>[]> {
        await this.doSavePresets({
            FilterData: JSON.stringify(filters),
        });
        return filters;
    }

    public async getFileFormats(): Promise<FileFormat[]> {
        return (await this.doGetFileFormats()).map((f) => new FileFormat(f));
    }

    public startExport(settings: CustomerExportSettings): Promise<void> {
        settings.SignalRClientId = this.sigR.connectionId;
        return this.doStartExport(settings);
    }

    public async startImport(exportSettings: CustomerImportSettings): Promise<void> {
        const formData = new FormData();
        formData.set("settings", JSON.stringify({
            FileType: exportSettings.FileFormat,
            SignalRClientId: this.sigR.connectionId,
        }));
        formData.set("File", exportSettings.File);

        if (!this.importSignalRSubscribed) {
            this.importSignalRSubscribed = true;
            this.sigR.on("customer", "CustomersImported", async (data) => {
                await this.showImportResultUseCase.execute(new ShowCustomerImportResultRequest({
                    Result: data,
                }));
            });
        }

        await this.doStartImport(formData);
    }

    public async getMultipleCustomers(customerIds: number[]): Promise<Customer[]> {
        return (await this.doGetMultipleCustomers(customerIds)).map(x => new Customer(x));
    }

    public async mergeCustomers(customerIdsToMerge: number[],
                                targetCustomer: Customer,
    ): Promise<void> {
        await this.doMergeCustomers({
            CustomerIdsToMerge: customerIdsToMerge,
            Customer: targetCustomer,
        });
    }

    public async updateMetaDataValue(metaData: CustomerMetaInformation,
    ): Promise<CustomerMetaInformation> {
        return new CustomerMetaInformation(await this.doUpdateMetaData(metaData));
    }

    constructor(restHandler: ngx.ResourceHandler,
                private readonly translator: TranslationService,
                @Inject("SignalRService") private readonly sigR: SignalRService,
                private readonly showImportResultUseCase: ShowCustomerImportResultUseCase,
    ) {
        super(restHandler);
    }
}
