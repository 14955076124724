import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {GeneralSettingsService} from "@app/app/common/service/da/general-settings.service";
import {AccessToken, OAuthService} from "@app/app/oauth/core";
import moment from "moment";
import {Observable, from, zip} from "rxjs";
import {filter, finalize, map, switchMap, takeUntil, tap} from "rxjs/operators";
import {PopupType} from "../../../shared/model/popup-type.enum";
import {PopupService} from "../../../shared/service/popup.service";
import {ShopViewModelDto} from "../data/shop-view-model.dto";
import {ShopsRepository} from "../data/shops.repository";
import {BaseDetailsDialogResponse} from "../model/base-details-dialog-response";
import {ShopKey} from "../model/shop-key";
import {ShopOauthPopupMessage} from "../model/shop-oauth-popup-message";
import {BaseDetailsDialogComponent} from "../presentation/base-details-dialog/base-details-dialog.component";
import {ShopLoadingDialogComponent} from "../presentation/shop-loading-dialog/shop-loading-dialog.component";

@Injectable({
	providedIn: "root",
})
export class ShopsService {
    constructor(
        private readonly shopRepository: ShopsRepository,
        private readonly matDialog: MatDialog,
        private readonly oauthService: OAuthService,
		private readonly popupService: PopupService,
		private readonly generalSettings: GeneralSettingsService,
    ) {}

    public getOauthDetails(logo: string, url: string): Observable<AccessToken & {shop?: string}> {
        const req$ = this.popupService
            .getMessage<ShopOauthPopupMessage>(url, PopupType.ConnectShopOauth)
            .pipe(
                switchMap((data) =>
                    from(this.oauthService.getChannelAccessToken(data)).pipe(
                        map((accessToken) => ({...accessToken, shop: data.shop})),
                    ),
                ),
            );

        return this.showShopLoadingDialogForRequest(req$, logo, "title.add_shop", "text.add_shop_description");
    }

    public getBaseDetails(
        shopKey: ShopKey,
        shopName: string,
        earliestStartDate = moment(Date.now()).add(-1, "y").toDate(),
    ): Observable<ShopViewModelDto> {
        const nameAndSyncDate$ = this.matDialog
            .open(BaseDetailsDialogComponent, {
                data: {shopKey, shopName, earliestStartDate},
                disableClose: true,
                autoFocus: false,
            })
            .afterClosed()
            .pipe(filter((res): res is BaseDetailsDialogResponse => !!res));

		const vatSettings$ = this.generalSettings.getVatSettings();

        return zip(nameAndSyncDate$, vatSettings$, this.shopRepository.getViewmodel(shopKey)).pipe(
            map(([nameAndSyncDate, vatSettings, viewModel]) => ({
				...viewModel,
				DefaultVatMode: vatSettings.DefaultVatMode,
				DefaultVatIndex: vatSettings.DefaultVatIndex,
                LastSyncDate: nameAndSyncDate.lastSyncDate,
                Name: nameAndSyncDate.name,
            })),
        );
    }

    public showShopLoadingDialogForRequest<T>(
        req$: Observable<T>,
        logo: string,
        title: string,
        text?: string,
    ): Observable<T> {
        const dialog = this.matDialog.open(ShopLoadingDialogComponent, {
            data: {logo, title, text},
            disableClose: true,
        });

        return req$.pipe(
            tap(() => dialog.close()),
            takeUntil(dialog.afterClosed()),
            finalize(() => dialog.close()),
        );
    }
}
