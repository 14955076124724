import {Injectable, inject} from "@angular/core";
import {OAuthService} from "@app/app/oauth/core";
import {Observable, from} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {PopupType} from "../../../../shared/model/popup-type.enum";
import {PopupService} from "../../../../shared/service/popup.service";
import {ShopViewModelDto} from "../../data/shop-view-model.dto";
import {ShopsRepository} from "../../data/shops.repository";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {GetOAuthViewModelCommand} from "../../service/command/get-oauth-view-model.command";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";
import {ReauthShopCommand} from "../../service/command/reauth-shop.command";
import {ShopsService} from "../../service/shops.service";

@Injectable({
	providedIn: "root",
})
export class ShopifyShop implements Shop {
    public readonly key = "shopify";
    public readonly name = "Shopify";
	public readonly type = ShopType.Shop;
	public readonly logo = `assets/images/logo/shops/${this.key}.svg`;
    public readonly featured = true;

    private readonly shopsRepository = inject(ShopsRepository);
    private readonly getOAuthViewModel = inject(GetOAuthViewModelCommand);
    private readonly shopsService = inject(ShopsService);
    private readonly popupService = inject(PopupService);
	private readonly addCommand = inject(OauthShopAddCommand);
	private readonly reauthCommand = inject(ReauthShopCommand);
	private readonly oauthService = inject(OAuthService);
    private readonly authUrl = "https://apps.shopify.com/billbee-de";

    public add(): Observable<never> {
        return this.addCommand.execute(
            this.popupService
                .getMessage<{shop: string}>(this.authUrl, PopupType.ShopifyShopName)
                .pipe(map(({shop}) => `/sync/shopifyauth?shop=${shop}&connectionType=channel`)),
			this.key,
			this.logo,
			"html.spotify_fetching_partner_url",
        );
	}
	
	public reAuth(shop: ShopViewModelDto): Observable<ShopViewModelDto> {
        const url$ = from(
            this.oauthService.getChannelAuthorizeUrl(this.key, shop.Id || 0, {
                ShopName: shop.ShopName || "",
            }),
        );
		return this.reauthCommand.execute(url$, this.key, this.logo, shop);
	}

    public saveShopWithNecessarySettings(): Observable<ShopViewModelDto> {
        return this.popupService.getMessage<{shop: string}>(this.authUrl, PopupType.ShopifyShopName).pipe(
            switchMap(({shop}) =>
                this.getOAuthViewModel.execute(
					this.key,
					this.logo,
                    this.name,
                    `/sync/shopifyauth?shop=${shop}&connectionType=channel`,
                ),
            ),
            map((viewModel) => ({
                ...viewModel,
                OAuthAccessToken: {
                    ...viewModel.OAuthAccessToken,
                    shop: viewModel.OAuthAccessToken.shop?.replace(".myshopify.com", "") || "",
                },
            })),
            switchMap((viewModel) =>
                this.shopsRepository.shopifyShopCheck(viewModel.OAuthAccessToken.shop).pipe(
                    map(({ShopExists}) => {
                        if (ShopExists) {
                            throw new Error("Shop already exists");
                        } else {
                            return viewModel;
                        }
                    }),
                ),
            ),
            map((viewModel) => ({
                ...viewModel,
                ShopName: viewModel.OAuthAccessToken.shop,
                ShopifyAccessToken: viewModel.OAuthAccessToken.Token,
            })),
            switchMap((model) =>
                this.shopsService.showShopLoadingDialogForRequest(
                    this.shopsRepository.saveShop(model),
                    this.logo,
                    "title.saving",
                    "text.saving_shop",
                ),
            ),
        );
    }
}
