import {Injectable} from "@angular/core";
import {ErrorHandlerService, Message, MessagingService, TranslationService} from "@bb-core/service";
import {RecipientOptions, Address, RecipientDetails} from "../entity";
import {AddressRepository, ShipmentRepository} from "../repository";
import {ShippingInteractionService} from "../service";

export class EditAddressRequest {
    public AddressId?: number = null;
    public Address?: Address = null;
    public OrderId?: number = null;

    constructor(obj?: Partial<EditAddressRequest>) {
        ctor(this, obj, {
            Relations: {
                Address,
            },
        });
    }
}

export abstract class EditAddressPresenter {
    /// Is called when the address was updated.
    public abstract displayUpdatedAddress(address: Address): void;
}

@Injectable({"providedIn": "root"})
export class EditAddressUseCase implements IUseCase<EditAddressRequest, EditAddressPresenter> {

    constructor(private readonly addressRepository: AddressRepository,
                private readonly shippingRepository: ShipmentRepository,
                private readonly interactionService: ShippingInteractionService,
                private readonly messagingService: MessagingService,
                private readonly translator: TranslationService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(request: EditAddressRequest,
                         presenter?: EditAddressPresenter,
    ): Promise<void> {
        const address = await this._getAddress(request);

        const options = await this._getRecipientOptions(request.OrderId);
        const oldId = address.Id;
        const recipientDetails = new RecipientDetails({
            Address: address,
            DefaultMailAddressId: options.DefaultMailAddresses.find(x => x.IsDefault)?.Key,
            CommercialMailAddressId: options.CommercialMailAddresses.find(x => x.IsDefault)?.Key,
            StatusUpdatesMailAddressId: options.StatusUpdatesMailAddresses.find(x => x.IsDefault)?.Key,
            Phone1Id: options.Phone1Numbers.find(x => x.IsDefault)?.Key,
            Phone2Id: options.Phone2Numbers.find(x => x.IsDefault)?.Key,
            FaxId: options.FaxNumbers.find(x => x.IsDefault)?.Key,
        });

        const modifiedRecipientDetails = await this.interactionService.editRecipientDetails(
            recipientDetails,
            options,
        );

        if (modifiedRecipientDetails == null) {
            return;
        }

        const modifiedAddress = modifiedRecipientDetails.Address;
        const updatedDetails = await this.shippingRepository.updateRecipientDetails(request.OrderId, modifiedRecipientDetails);

        if (modifiedAddress?.Id != null
            && modifiedAddress.Id !== 0
            && Number(oldId) !== Number(updatedDetails.Address.Id)) {
            this._showNewAddressWasCreatedDialog();
        }
        presenter?.displayUpdatedAddress(updatedDetails.Address);
    }

    private async _getAddress(request: EditAddressRequest): Promise<Address> {
        if (request.Address != null) {
            return request.Address;
        }

        if (request.AddressId == null) {
            return new Address();
        }

        try {
            return await this.addressRepository.getSingle(request.AddressId);
        } catch (e) {
            await this.errorHandler.handleException(e, "text.failed_to_load_the_address", true);
        }
    }

    private async _updateAddress(modifiedAddress: Address): Promise<Address> {
        try {
            return await this.addressRepository.updateOrCopy(modifiedAddress);
        } catch (e) {
            await this._handleSaveError(e);
        }
    }

    private _showNewAddressWasCreatedDialog(): void {
        const title = this.translator.translate("title.new_address_created");
        const message = this.translator.translate("text.new_address_created");
        this.messagingService.showMessage(Message.blocking({title, message})).then();
    }

    private async _createAddress(address: Address): Promise<Address> {
        try {
            return await this.addressRepository.create(address);
        } catch (e) {
            await this._handleSaveError(e);
        }
    }

    private async _handleSaveError(e): Promise<void> {
        await this.errorHandler.handleException(e, "text.failed_to_save_the_address", true);
    }

    private async _getRecipientOptions(orderId: number): Promise<RecipientOptions> {
        return await this.shippingRepository.getRecipientOptions(orderId);
    }
}
