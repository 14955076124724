import {Injectable} from "@angular/core";
import {CustomerMetaInformation, CustomerMetaType} from "../entity";
import {CustomerInteractionService, CustomerRepository} from "@app/app/customer/core";
import {ErrorHandlerService, Message, MessagingService, TranslationService} from "@bb-core/service";

export class EditCustomerMetaDataRequest {

    public MetaData?: CustomerMetaInformation = null;

    constructor(obj?: Partial<EditCustomerMetaDataRequest>) {
        ctor(this, obj, {
            Relations: {
                MetaData: CustomerMetaInformation,
            },
        });
    }
}

export abstract class EditCustomerMetaDataPresenter {
    public abstract displayUpdatedMetaData(metaData: CustomerMetaInformation,
    ): void;
}

@Injectable({providedIn: "root"})
export class EditCustomerMetaDataUseCase implements IUseCase<EditCustomerMetaDataRequest, EditCustomerMetaDataPresenter> {

    constructor(private readonly customerInteractionService: CustomerInteractionService,
                private readonly customerRepository: CustomerRepository,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(request: EditCustomerMetaDataRequest,
                         presenter?: EditCustomerMetaDataPresenter,
    ): Promise<void> {
        if (!EditCustomerMetaDataUseCase._verifyRequest(request)) {
            return;
        }

        const metaDataToUpdate = await this.customerInteractionService.editMetaData(
            request.MetaData,
        );
        if (metaDataToUpdate == null) {
            return;
        }
        
        const updatedMetaData = await this._updateMetaData(metaDataToUpdate);
        presenter?.displayUpdatedMetaData(updatedMetaData);
    }

    private static _verifyRequest(request: EditCustomerMetaDataRequest): boolean {
        return request?.MetaData?.Id != null;
    }

    private async _updateMetaData(
        metaData: CustomerMetaInformation,
    ): Promise<CustomerMetaInformation> {
        try {
            const savedMetaData = await this.customerRepository.updateMetaDataValue(metaData);
            const message = this.translator.translate(metaData.Type == CustomerMetaType.MailAddress
                ? "flash.mail_address_updated"
                : "flash.phone_number_updated",
            );
            await this.messaging.showMessage(Message.transient({message}));
            return savedMetaData;
        } catch (e) {
            await this.errorHandler.handleException(
                e,
                metaData.Type == CustomerMetaType.MailAddress
                    ? "flash.update_mail_address_failed"
                    : "flash.update_phone_number_failed",
                true,
            );
            throw e;
        }
    }
}
