import {Address} from "./address";

export class RecipientDetails {
    public Address: Address = null;
    public DefaultMailAddressId: number = null;
    public CommercialMailAddressId: number = null;
    public StatusUpdatesMailAddressId: number = null;
    public Phone1Id: number = null;
    public Phone2Id: number = null;
    public FaxId: number = null;

    constructor(obj?: Partial<RecipientDetails>) {
        ctor(this, obj, {
            Relations: {
                "Address": Address,
            },
        });
    }
}
