import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {OverlayModule} from "@angular/cdk/overlay";
import {AsyncPipe, NgFor, NgIf} from "@angular/common";
import {ChangeDetectionStrategy, Component, Inject, OnInit} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Router} from "@angular/router";
import {SHOPS} from "@app/app/partners/shops/shops.token";
import {provideComponentStore} from "@ngrx/component-store";
import {TranslateModule} from "@ngx-translate/core";
import {Shop} from "@partners/shops/model/shop";
import {Subject, take} from "rxjs";
import {ShopViewModelDto} from "../../../../partners/shops/data/shop-view-model.dto";
import {MultiSelectService} from "../../../../shared/service/multi-select-service";
import {DataPageComponent} from "../../../../shared/ui/data-page/data-page.component";
import {BbNewListItemCardComponent} from "../../../../shared/ui/new-list-item-card/new-list-item-card.component";
import {BbLoadingCardComponent} from "../../../../ui/bb-loading-card/bb-loading-card.component";
import {ShopSyncState} from "../../model/shop-sync-state.enum";
import {GeneralShopSettingsComponent} from "../../presentation/general-shop-settings/general-shop-settings.component";
import {ShopCardComponent} from "../../presentation/shop-card/shop-card.component";
import {ShopListStore} from "./shop-list.store";

@Component({
    selector: "bb-shop-list",
    templateUrl: "./shop-list.component.html",
    styleUrls: ["./shop-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger("listAnimation", [
            transition("* <=> *", [
                query(
                    ":enter",
                    [style({opacity: 0}), stagger("30ms", animate("600ms ease-out", style({opacity: 1})))],
                    {optional: true},
                ),
                query(":leave", animate("0.3s", style({opacity: 0})), {optional: true}),
            ]),
        ]),
    ],
    providers: [provideComponentStore(ShopListStore)],
    standalone: true,
    imports: [
        DataPageComponent,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        NgFor,
        NgIf,
        OverlayModule,
        MatCardModule,
        GeneralShopSettingsComponent,
        MatCheckboxModule,
        MatDividerModule,
        BbLoadingCardComponent,
        MatTooltipModule,
        BbNewListItemCardComponent,
        ShopCardComponent,
    ],
})
export class ShopListComponent implements OnInit {
    public readonly multiSelect = new MultiSelectService(this.componentStore.shops$, (shop) => shop.Id || 0);
    public readonly AccountSyncState = ShopSyncState;

    public readonly shopsLoading$ = this.componentStore.shopsLoading$;
    public readonly loadingIds$ = this.componentStore.loadingIds$;
    public readonly disableReload$ = this.componentStore.disableReload$;
    public readonly reload$ = new Subject<void>();

    public settingsOverlayOpened = false;

    constructor(
        private readonly componentStore: ShopListStore,
        private readonly router: Router,
        @Inject(SHOPS) public readonly shops: Shop[],
    ) {}

    public ngOnInit(): void {
        this.componentStore.loadShops(this.reload$.asObservable());
    }

    public addNewShop(): void {
        this.componentStore.addNewShop();
    }

    public edit(id: number): void {
        this.router.navigateByUrl(`/settings/shops/${id}`);
    }

    public toggleAllRows(): void {
        this.multiSelect.areAllSelected$.pipe(take(1)).subscribe((allSelected) => {
            if (allSelected) {
                this.multiSelect.reset();
                return;
            }

            this.multiSelect.selectAll();
        });
    }

    public onSelectionChange(id: number): void {
        this.multiSelect.toggle(id);
    }

    public onClickShopCard(shop: ShopViewModelDto): void {
        if (shop.LastSyncState === ShopSyncState.ReauthRequired) {
            this.reAuthShop(shop);
            return;
        }
        this.editShop(shop.Id || 0);
    }

    public editShop(shopId: number): void {
        this.router.navigate([`/settings/shops/${shopId}`]);
    }

    public reAuthShop(shop: ShopViewModelDto): void {
        this.componentStore.reAuthShop(shop);
    }

    public switchToManualShop(shopIds: number[]): void {
        this.componentStore.switchToManualShops(shopIds);
    }

    public deleteShops(shopIds: number[]): void {
        this.componentStore.deleteShops(shopIds);
    }

    public trackBy(index: number, shop: ShopViewModelDto): number {
        return shop.Id || 0;
    }
}
