import {Pipe, PipeTransform} from "@angular/core";
import moment from "moment";
import {SyncMode} from "../../model/sync-mode.enum";

@Pipe({
    name: "isLongPeriod",
})
export class IsLongPeriodPipe implements PipeTransform {
    public transform(value: moment.Moment | Date, mode: SyncMode): any {
        const val = moment(value).toDate();
        const diffInDays = moment(Date.now()).diff(moment(val), "d");

        return mode != SyncMode.never && Math.abs(diffInDays) > 30;
    }
}
