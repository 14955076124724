<mat-form-field>
    @if (label) {
        <mat-label>{{ label }}</mat-label>
    }
    <input matInput 
           [matDatepicker]="datepicker" 
           (focus)="datepicker.open()"
           [formControl]="control" 
           [min]="min"
           [required]="required"
           [matTooltip]="tooltip"
    />
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker> </mat-datepicker>
    <mat-error *ngFor="let error of control.errors | bbErrors: customErrors">{{ error }}</mat-error>
</mat-form-field>
