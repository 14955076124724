import {Injectable} from "@angular/core";
import {ErrorHandlerService} from "@bb-core/service";
import {CustomerAddress, CustomerRepository} from "@app/app/customer/core";

export class LoadAddressesRequest {
    public CustomerId: number = null;

    constructor(obj?: Partial<LoadAddressesRequest>) {
        ctor(this, obj);
    }
}

export abstract class LoadAddressesPresenter {
    public abstract displayAddresses(addresses: CustomerAddress[]): void ;
}

@Injectable({providedIn: "root"})
export class LoadAddressesUseCase implements IUseCase<LoadAddressesRequest, LoadAddressesPresenter> {

    constructor(private readonly customerRepository: CustomerRepository,
                private readonly errorHandler: ErrorHandlerService,
    ) {
    }

    public async execute(request: LoadAddressesRequest,
                         presenter?: LoadAddressesPresenter,
    ): Promise<void> {
        try {
            const addresses = await this.customerRepository.getAddresses(request.CustomerId);
            presenter?.displayAddresses(addresses);
        } catch (e) {
            await this.errorHandler.handleException(e, "text.failed_to_load_the_addresses", true);
            throw e;
        }
    }
}
