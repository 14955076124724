import {NgIf} from "@angular/common";
import {ChangeDetectionStrategy, Component, Inject, OnInit} from "@angular/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import moment from "moment";
import {BbDatePickerComponent} from "../../../../shared/ui/form/bb-datepicker/bb-datepicker.component";
import {BbInputComponent} from "../../../../shared/ui/form/bb-input/bb-input.component";
import {BbSelectComponent} from "../../../../shared/ui/form/bb-select/bb-select.component";
import {ShopKey} from "../../model/shop-key";
import {ShopSyncMode} from "../../model/shop-sync-mode.enum";
import { MatButtonModule } from "@angular/material/button";

interface BaseDetailsDialogData {
    shopKey: ShopKey;
    shopName: string;
    earliestStartDate: Date;
}

@Component({
    selector: "app-base-details-dialog",
    templateUrl: "./base-details-dialog.component.html",
    styleUrls: ["./base-details-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatDialogModule,
        ReactiveFormsModule,
        BbInputComponent,
		BbSelectComponent,
		BbDatePickerComponent,
		MatButtonModule,
		TranslateModule,
    ],
})
export class BaseDetailsDialogComponent implements OnInit {
    public readonly imageUrl = `assets/images/logo/shops/${this.data.shopKey}.png`;

    public readonly ShopSyncMode = ShopSyncMode;
    public readonly syncOptions: KeyValuePair<string, string>[] = [
        {Key: "label.today", Value: ShopSyncMode.Today},
        {Key: "label.last_seven_days", Value: ShopSyncMode.Last7Days},
        {Key: "label.last_thirty_days", Value: ShopSyncMode.Last30Days},
        {Key: "label.custom", Value: ShopSyncMode.Custom},
    ];

    public readonly form = new FormGroup({
        name: new FormControl(`${this.data.shopName}-Shop`, Validators.required),
        syncMode: new FormControl("today"),
        customDate: new FormControl(""),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: BaseDetailsDialogData,
        private readonly ref: MatDialogRef<BaseDetailsDialogComponent>,
    ) {}

    public ngOnInit(): void {
        this.form.controls.syncMode.valueChanges.pipe(takeUntilDestroyed()).subscribe((syncMode) => {
            if (syncMode === ShopSyncMode.Custom) {
                this.form.controls.customDate.setValidators(Validators.required);
            } else {
                this.form.controls.customDate.clearValidators();
            }
            this.form.controls.customDate.updateValueAndValidity({emitEvent: false});
        });
    }

    public save(): void {
        if (this.form.invalid) {
            return;
        }

        this.ref.close({
            name: this.form.value.name,
            lastSyncDate: this.getLastSyncDate(),
        });
    }

    private getLastSyncDate(): Date {
        let date = moment(this.form.value.customDate).toDate();

        switch (this.form.value.syncMode) {
            case ShopSyncMode.Today:
                date = moment(Date.now()).toDate();
                break;
            case ShopSyncMode.Last7Days:
                date = moment(Date.now()).add(-7, "d").toDate();
                break;
            case "last-30-days":
                date = moment(Date.now()).add(-30, "d").toDate();
                break;
        }

        date.setHours(0, 0, 0, 0);
        return date;
    }
}
