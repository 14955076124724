<bb-list-item-card
    *ngIf="shop?.Partner | bbShopFromPartnerKey as partner"
    [title]="partner.name"
    [imgSrc]="partner.logo || null"
    [border]="shop | bbStatusColorFromShop"
    [checked]="shop?.selected"
    [loading]="loading"
    (selectionChange)="onSelectionChange.emit()"
    (onClick)="onClick.emit()"
>
    <button mat-menu-item (click)="onClickEdit.emit()">
        {{ "label.edit_shop" | translate }}
    </button>
    <button *ngIf="partner.reAuth" mat-menu-item (click)="onClickReAuth.emit()">
        {{ "title.reauth_shop" | translate }}
    </button>
    <button mat-menu-item (click)="onClickSwitchToManual.emit()">
        {{ "tooltip.switch_to_manual_shop" | translate }}
    </button>
    <button mat-menu-item (click)="onClickDelete.emit()">
        {{ "tooltip.delete" | translate }}
    </button>
    <div content>
        <div class="subtitle">{{ partner.name }}</div>
        <div class="title">{{ shop.Name }}</div>
        <div class="infos-wrapper">
            <div class="info-wrapper">
                <mat-icon fontIcon="fa-clock" [inline]="true"></mat-icon>
                <div>
                    <span *ngIf="shop.LastSyncDate">
                        {{ shop.LastSyncDate | date }} {{ shop.LastSyncDate | date : "HH:mm" }}
                    </span>
                    <span *ngIf="!shop.LastSyncDate">
                        {{ "text.not_yet_fetched" | translate }}
                    </span>
                </div>
            </div>
            <div class="info-wrapper">
                <mat-icon fontIcon="fa-rotate" [inline]="true"></mat-icon>
                <div>
                    <span [innerHTML]="shop.StateSyncModeText"></span>
                </div>
            </div>
        </div>
        <mat-divider *ngIf="!partner?.hideSyncStateText"></mat-divider>
    </div>
    <div footer *ngIf="!partner?.hideSyncStateText">
        <div [class]="[shop | bbStatusColorFromShop, 'info-wrapper']">
            <mat-icon [fontIcon]="shop?.LastSyncState | bbIconFromSyncState" [inline]="true"></mat-icon>
            <div>
                {{
                    (shop?.LastSyncState === 255
                        ? "label.waiting_for_import"
                        : shop?.LastSyncState === 254
                        ? "label.ready"
                        : shop?.LastSyncStateText
                    ) | translate
                }}
            </div>
        </div>
    </div>
</bb-list-item-card>
