export interface ExternalLoginData {
    ProviderName: string;
    UserId: string;
    UserName: string;
}

export class SignUpRequest {
    public EMail: string = "";
    public Password: string = "";
    public SignupSource: string = "";
    public AcceptTerms: boolean = false;
    public Newsletter: boolean = false;
    public ExternalLoginData?: ExternalLoginData = null;

    public constructor(obj?: Partial<SignUpRequest>) {
        if (!obj) {
            return;
        }
        Object.keys(this).forEach(k => {
            if (k in obj) {
                this[k] = obj[k];
            }
        });
    }
}
