import {Injectable} from "@angular/core";
import {Customer} from "../../entity";
import {CustomerRepository} from "@app/app/customer/core";
import {ErrorHandlerService, Message, MessagingService, TranslationService} from "@bb-core/service";

export class SaveCustomerRequest {
    public Customer: Customer = null;
    public UpdateAddresses: boolean = false;
    public MergedAddresses: { [key: number]: number[] } = null;

    constructor(obj?: Partial<SaveCustomerRequest>) {
        ctor(this, obj);
    }
}

export abstract class SaveCustomerPresenter {
    public abstract displaySavedCustomer(customer: Customer): void;
}

@Injectable({providedIn: "root"})
export class SaveCustomerUseCase implements IUseCase<SaveCustomerRequest, SaveCustomerPresenter> {

    constructor(private readonly customerRepository: CustomerRepository,
                private readonly errorHandler: ErrorHandlerService,
                private readonly translator: TranslationService,
                private readonly messaging: MessagingService,
    ) {
    }

    public async execute(request: SaveCustomerRequest,
                         presenter?: SaveCustomerPresenter,
    ): Promise<void> {
        if (!(await this.validateRequest(request))) {
            return;
        }
        const {Customer: customer} = request;
        if (!request.UpdateAddresses) {
            customer.Addresses = null;
        }
        const newOrUpdatedCustomer = await this.save({
            ...customer,
            MergedAddresses: request.MergedAddresses,
        } as any);
        presenter?.displaySavedCustomer(newOrUpdatedCustomer);
        await this.showSuccessMessage();
    }

    private async save(customer: Customer): Promise<Customer> {
        try {
            return await this.customerRepository.save(customer);
        } catch (e) {
            await this.errorHandler.handleException(e, "flash.save_customer_failed", true, {
                "duplicate_number": "error.duplicate_customer_number",
            });
            throw e;
        }
    }

    private async validateRequest(request: SaveCustomerRequest): Promise<boolean> {
        if (request.Customer == null) {
            return false;
        }
        if (request.Customer.IsArchived) {
            const message= this.translator.translate("error.customer.can_not_save_archived_customer");
            const title = this.translator.translate("title.error");
            await this.messaging.showMessage(Message.blocking({message, title}));
            return false;
        }

        return true;
    }

    private async showSuccessMessage(): Promise<void> {
        const message = this.translator.translate("flash.customer_saved");
        await this.messaging.showMessage(Message.transient({message}));
    }
}
