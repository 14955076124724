import {NgModule} from "@angular/core";
import {BbModule} from "@app/app/common/module/bb.module";
import {MaterialModule} from "@app/app/common/module/material.module";
import {CommonModule} from "@angular/common";

import * as core from "./core";
import * as data from "./data";
import * as infrastructure from "./infrastructure";
import * as presentation from "./presentation";
import {ShopsSettingsService} from "@app/app/common/service/da/shops-settings.service";

@NgModule({
    declarations: [
        presentation.CustomerFormComponent,
        presentation.EditCustomerAddressComponent,
        presentation.EditCustomerSourceComponent,
        presentation.MergeCustomerAddressesComponent,
        presentation.ExportCustomersComponent,
        presentation.ImportCustomersComponent,
        presentation.MergeCustomersComponent,
        presentation.ImportResultComponent,
        presentation.EditMetaDataComponent,
    ],
    imports: [
        BbModule,
        MaterialModule,
        CommonModule,
    ],
    exports: [
        presentation.CustomerFormComponent,
        presentation.EditCustomerAddressComponent,
        presentation.EditCustomerSourceComponent,
        presentation.MergeCustomerAddressesComponent,
        presentation.ExportCustomersComponent,
        presentation.ImportCustomersComponent,
        presentation.MergeCustomersComponent,
        presentation.EditMetaDataComponent,
    ],
    providers: [
        core.LoadCustomerUseCase,
        core.LoadAddressesUseCase,
        core.EditCustomerAddressUseCase,
        core.EditCustomerSourceUseCase,
        core.MergeAddressesUseCase,
        core.SaveCustomerUseCase,
        core.DeleteCustomerUseCase,
        core.ExportCustomersUseCase,
        core.ImportCustomersUseCase,
        core.MergeCustomersUseCase,
        core.ShowCustomerListUseCase,
        core.ShowCustomerImportResultUseCase,
        {provide: core.CustomerRepository, useClass: data.ApiCustomerRepository},
        {provide: core.CustomerPaymentRepository, useClass: data.ApiCustomerPaymentRepository},
        {provide: core.ShopRepository, useClass: ShopsSettingsService},
        {provide: core.CustomerOrderRepository, useClass: data.ApiCustomerOrderRepository},
        {provide: core.CustomerInteractionService, useClass: infrastructure.MaterialCustomerInteractionService},
    ],
})
export class CustomerPublicApiModule {

}
