import {CommonModule} from "@angular/common";
import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {MatBadgeModule} from "@angular/material/badge";
import {MultiSelectService} from "../../service/multi-select-service";

@Component({
    selector: "bb-data-page",
    templateUrl: "./data-page.component.html",
    styleUrls: ["./data-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatBadgeModule],
})
export class DataPageComponent {
    @Input() public title = "";
    @Input() public showBadge = false;
    @Input() public multiSelect: MultiSelectService<any, any> | null = null;

    constructor() {}
}
