import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {AccountResetSettingsDto} from "./account-reset-settings.dto";
import {AvvSettingsDto} from "./avv-settings.dto";
import {ExternalLoginDto} from "./external-login.dto";
import {MailNotificationSettingsDto} from "./mail-notification-settings.dto";
import {ServicePinSettingsDto} from "./service-pin-settings.dto";
import {UserProfileDto} from "./user-profile.dto";


@Injectable({providedIn: "root"})
export class AccountRepository {
    private readonly baseUrl = `${environment.baseUrl}/account`;

    constructor(private readonly httpClient: HttpClient) {}

    public getMailNotificationSettings(): Observable<MailNotificationSettingsDto> {
        return this.httpClient.get<MailNotificationSettingsDto>(`${this.baseUrl}/mail-notification-settings`);
    }

    public setEmailNotificationSettings(settings: MailNotificationSettingsDto): Observable<UserProfileDto> {
        return this.patchProfile(settings);
    }

    public getDetails(): Observable<UserProfileDto> {
        return this.httpClient.get<UserProfileDto>(`${this.baseUrl}/details`);
    }

    public patchProfile(user: Partial<UserProfileDto>): Observable<UserProfileDto> {
        return this.httpClient.patch<UserProfileDto>(`${this.baseUrl}/profile`, user);
    }

    public getExternalLogins(): Observable<ExternalLoginDto[]> {
        return this.httpClient.get<ExternalLoginDto[]>(`${this.baseUrl}/external-logins`);
    }

    public deleteExternalLogin(externalLogin: ExternalLoginDto): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/externalLogins/${externalLogin.Id}`);
    }

    public getServicePinSettings(): Observable<ServicePinSettingsDto> {
        return this.httpClient.get<ServicePinSettingsDto>(`${this.baseUrl}/service-pin-settings`);
    }

    public getAvvSettings(): Observable<AvvSettingsDto> {
        return this.httpClient.get<AvvSettingsDto>(`${this.baseUrl}/avv-settings`);
    }

    public resetAccount(settings: AccountResetSettingsDto): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/reset`, settings);
    }

    public deleteAccount(confirmDeletion: boolean): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/delete`, {ConfirmDeletion: confirmDeletion});
    }
}
