import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {EmptyComponent} from "@app/app/common/component/empty.component";
import {BbModule} from "@app/app/common/module/bb.module";
import {BbCheckboxComponent} from "../shared/ui/form/bb-checkbox/bb-checkbox.component";
import {BbDatePickerComponent} from "../shared/ui/form/bb-datepicker/bb-datepicker.component";
import {BbInputComponent} from "../shared/ui/form/bb-input/bb-input.component";
import {BbSelectComponent} from "../shared/ui/form/bb-select/bb-select.component";
import {BbSlideToggleComponent} from "../shared/ui/form/bb-slide-toggle/bb-slide-toggle.component";
import {BbLoadingCardComponent} from "../ui/bb-loading-card/bb-loading-card.component";
import {AccountComponent} from "./container/account.component";
import {SecurityComponent} from "./container/security/security.component";
import {AccountSettingsContainerComponent} from "./container/settings/settings.component";
import {Add2FaDialogComponent} from "./presentation/add-2fa-dialog/add2-fa-dialog.component";
import {AvvSettingsCardComponent} from "./presentation/avv-settings-card/avv-settings-card.component";
import {DeleteAccountCardComponent} from "./presentation/delete-account-card/delete-account-card.component";
import {EmailSettingsCardComponent} from "./presentation/email-settings-card/email-settings-card.component";
import {MapToAllExternalLoginsPipe} from "./presentation/email-settings-card/map-to-all-external-logins.pipe";
import {EnterAccountSyncSettingsDialogComponent} from "./presentation/enter-account-sync-settings-dialog/enter-account-sync-settings-dialog.component";
import {IsLongPeriodPipe} from "./presentation/enter-account-sync-settings-dialog/is-long-period.pipe";
import {EnterResetAccountSettingsDialogComponent} from "./presentation/enter-reset-account-settings-dialog/enter-reset-account-settings-dialog.component";
import {ExternalLoginButtonComponent} from "./presentation/external-login-button/external-login-button.component";
import {MailNotificationSettingsCardComponent} from "./presentation/mail-notification-settings-card/mail-notification-settings-card.component";
import {OverviewComponent} from "./presentation/overview/overview.component";
import {Request2FaDialogComponent} from "./presentation/request-2fa-dialog/request2-fa-dialog.component";
import {ResetAccountCardComponent} from "./presentation/reset-account-card/reset-account-card.component";
import {ServicePinSettingsCardComponent} from "./presentation/service-pin-settings-card/service-pin-settings-card.component";


@NgModule({
    declarations: [
        AccountComponent,
        OverviewComponent,
        Add2FaDialogComponent,
        Request2FaDialogComponent,
        SecurityComponent,
        EnterResetAccountSettingsDialogComponent,
        EnterAccountSyncSettingsDialogComponent,
        AccountSettingsContainerComponent,
        ExternalLoginButtonComponent,
        IsLongPeriodPipe,
        EmailSettingsCardComponent,
        ServicePinSettingsCardComponent,
        MailNotificationSettingsCardComponent,
        AvvSettingsCardComponent,
        ResetAccountCardComponent,
        DeleteAccountCardComponent,
        MapToAllExternalLoginsPipe,
    ],
    imports: [
        BbModule,
        CommonModule,
        BbSelectComponent,
        BbInputComponent,
        BbSlideToggleComponent,
        BbCheckboxComponent,
        BbDatePickerComponent,
        BbLoadingCardComponent,
        RouterModule.forChild([
            {
                component: AccountComponent,
                path: "",
                children: [
                    {component: OverviewComponent, path: "overview"},
                    {component: SecurityComponent, path: "security"},
                    {component: AccountSettingsContainerComponent, path: "settings"},
                    {
                        children: [{component: EmptyComponent, path: "invoices"}],
                        component: EmptyComponent,
                        path: "invoices-and-payment",
                    },
                ],
            },
        ]),
    ],
})
export class AccountModule {}
