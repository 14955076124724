import {Pipe, PipeTransform} from "@angular/core";
import {ShopSyncState} from "../../model/shop-sync-state.enum";

@Pipe({
    name: "bbIconFromSyncState",
    standalone: true,
})
export class IconFromSyncStatePipe implements PipeTransform {
    transform(syncState: ShopSyncState): string {
		if (syncState === ShopSyncState.AwaitingFirstImportOrAccountReauthed) {
			return "fa-hourglass-half";
		}

		if (syncState === ShopSyncState.ReauthRequired || syncState === ShopSyncState.SyncFailedReconnectRequired) {
			return "fa-remove";
		}
                        
		return "fa-check";
    }
}
